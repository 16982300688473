exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-careers-jsx": () => import("./../../../src/pages/careers.jsx" /* webpackChunkName: "component---src-pages-careers-jsx" */),
  "component---src-pages-consumer-foods-jsx": () => import("./../../../src/pages/consumer-foods.jsx" /* webpackChunkName: "component---src-pages-consumer-foods-jsx" */),
  "component---src-pages-financial-services-jsx": () => import("./../../../src/pages/financial-services.jsx" /* webpackChunkName: "component---src-pages-financial-services-jsx" */),
  "component---src-pages-home-jsx": () => import("./../../../src/pages/home.jsx" /* webpackChunkName: "component---src-pages-home-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-information-technology-and-innovation-jsx": () => import("./../../../src/pages/information-technology-and-innovation.jsx" /* webpackChunkName: "component---src-pages-information-technology-and-innovation-jsx" */),
  "component---src-pages-leisure-jsx": () => import("./../../../src/pages/leisure.jsx" /* webpackChunkName: "component---src-pages-leisure-jsx" */),
  "component---src-pages-newspage-jsx": () => import("./../../../src/pages/newspage.jsx" /* webpackChunkName: "component---src-pages-newspage-jsx" */),
  "component---src-pages-our-group-jsx": () => import("./../../../src/pages/our-group.jsx" /* webpackChunkName: "component---src-pages-our-group-jsx" */),
  "component---src-pages-plantation-services-jsx": () => import("./../../../src/pages/plantation-services.jsx" /* webpackChunkName: "component---src-pages-plantation-services-jsx" */),
  "component---src-pages-property-jsx": () => import("./../../../src/pages/property.jsx" /* webpackChunkName: "component---src-pages-property-jsx" */),
  "component---src-pages-retail-jsx": () => import("./../../../src/pages/retail.jsx" /* webpackChunkName: "component---src-pages-retail-jsx" */),
  "component---src-pages-sustainability-jsx": () => import("./../../../src/pages/sustainability.jsx" /* webpackChunkName: "component---src-pages-sustainability-jsx" */),
  "component---src-pages-transportation-jsx": () => import("./../../../src/pages/transportation.jsx" /* webpackChunkName: "component---src-pages-transportation-jsx" */)
}

